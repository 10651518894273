// Copyright (C) 2005-2011 David Caldwell, All Rights Reserved.

var plat = require('./platform');
var dom = require('./dom'), Cookie=dom.Cookie, doc=dom.doc;
var ajax = require('./ajax'), loadJSON=ajax.loadJSON, makeurl=ajax.makeurl;
var Alert = require('./alert');
var lib = require('./lib'), rot13=lib.rot13, merge_into=lib.merge_into;
var prnt = require('./print'), sprintf=prnt.sprintf;

function login()
{
    this.div = { login: doc.id("login"),
                 login_error: doc.id("login-error"),
                 user_menu: doc.id("user-menu"),
                 logged_in_user: doc.id("logged-in-user"),
                 logout: doc.id("logout") };
    this.state = "idle";
    this.div.logout.onclick = function() { this.logout(); return false }.bind(this);
    this.update_cookies();
}

login.prototype.update_cookies = function() {
    Cookie.reload();
    if (this.is_logged_in()) {
        this.user = Cookie.list.greenfelt_user;
        this.is_admin = !!Cookie.list.greenfelt_admin;
    }
    this.update_div();
}

login.prototype.is_logged_in = function() {
    return Cookie.list.greenfelt_user;
}

login.prototype.set_error = function(message) {
    this.error = message;
    this.logout();
};

login.prototype.logout = function() {
    loadJSON(makeurl("user", "logout"), function() {
        Cookie.reload();
        delete Cookie.list.greenfelt_user; // Make sure this doesn't come back immediately, so we don't get 'Logout
                                           // "undefined"' in our button. It *will* show up on the next reload.
        delete this.user;
        delete this.is_admin;
        this.update_div();
    }.bind(this));
};

login.prototype.on_login_f = function() {};
login.prototype.on_login = function(f) {
    if (f) {
        var old_f = this.on_login_f;
        this.on_login_f = function () {
            old_f();
            f();
        };
    } else
        this.on_login_f();
}

login.prototype.authenticate = function(user, pass, create, after) {
    if (this.state == "authenticating") return;
    var old_state = this.state;
    this.state="authenticating";
    this.update_div();

    loadJSON(makeurl("user", create ? "create" : "login", { user: user, pass: rot13(pass) }), function(result, error, status) {
        if (result == undefined) {
            this.error = sprintf("%j", error);
        } else {
            Cookie.reload();
            this.user = Cookie.list.greenfelt_user;
            this.is_admin = !!Cookie.list.greenfelt_admin;
            this.error=undefined;
            this.on_login();
            if (window._paq && create)
                window._paq.push(['trackGoal', 3]);
        }
        this.state=old_state;
        this.update_div();
        if (after) after();
    }.bind(this));
}

function gen_return_wrapper(input, extra) {
    plat.register_key_events(input, function(event) {
        if (event.key == "return") {
            if (extra) extra();
            if (input.onchange) input.onchange();
            return false;
        }
    });
}

login.prototype.update_div = function() {
    doc.clear(this.div.login_error);
    var show_user_menu = false;
    if (this.state == "creating")
        this.div.login.className = "creating";
    else if (this.state == "authenticating")
        this.div.login.className = "authenticating";
    else if (this.is_logged_in()) {
        this.div.login.className = "logged-in";
        this.div.logged_in_user.firstChild.textContent = this.user;
    } else if (this.state == "login-dialog")
        this.div.login.className = "login-dialog";
    else {
        this.div.login.className = "logged-out";
        if (this.error)
            doc([this.div.login_error,
                 ["span", { className: "login-error" }, "* " + this.error + " * "]]);
        doc.id("login-button")   .onclick = ()=>this.login_dialog();
        doc.id("new-user-button").onclick = ()=>this.create_dialog();
    }
}

login.prototype.login_dialog = function() {
    var e,u,p,l,c,s,f;
    var a = new Alert(doc(
        ["div",
         ["span", { className: "login-error" }, _=>e=_ ],
         ["form", _=>f=_,
          ["table",
           ["tbody",
            ["tr",
             ["td", ["label", {'for':"username" }, "Username:"]],
             ["td", ["input", { id:"username", name:"username", type:"text",
                                size:"10", autocapitalize: "off", autocorrect: false }, _=>u=_ ]]],
            ["tr",
             ["td", ["label", {'for':"password" }, "Password:"]],
             ["td", ["input", { id:"password", name:"password", type:"password", size:"10" }, _=>p=_ ]]]]],
          ["p",
           ["input", { type:"submit", name: "submit", value:"Login"  }, _=>l=_ ],
           ["input", { type:"button", value:"Cancel" }, _=>c=_ ],
           ["img", { src:doc.id("spinner").src, style:{ display:"none" } }, _=>s=_ ]]]]
    ), { title:"Login", className: "login-dialog", cancel: ()=>this.update_div() });
    this.state = 'login-dialog';  // Hide the normal stuff;
    this.update_div();
    var got_error = function() {
        doc.clear(e);
        doc([e, "Error: "+this.error]);
        s.style.display = "none";
        l.disabled = false;
        c.disabled = false;
    }.bind(this);
    f.onsubmit = function(ev) {
        s.style.display = "inline";
        l.disabled = true;
        c.disabled = true;
        this.authenticate(u.value, p.value, false, function() {
            if (this.error)
                got_error();
            else
                c.onclick(); // cancel button
        }.bind(this));
        ev.preventDefault();
    }.bind(this);
    c.onclick = function() {
        this.state = 'idle';
        this.error = undefined;
        a.close();
        this.update_div();
    }.bind(this);
    gen_return_wrapper(u, function() { p.focus(); });
    gen_return_wrapper(p, function() { l.click(); });
    u.focus();
}

login.prototype.create_dialog = function() {
    if (this.state == "creating") return; // Already have the dialog open!
    this.state = "creating";
    this.update_div();

    var error, form, ok, cancel, nuser, npass, npass2;
    var alert = new Alert(doc(
        ["div",
         ["p",
          "Thanks for joining Green Felt. Please enter a login name and a password. " +
          "The login name will be what you use to log in to green felt and what "+
          "you will be known as to other users (in high score tables, etc.). " +
          "Either may be changed at a later date."],
         ["ul", { style: {color: "orange"} }, _=>error=_],
         ["form", _=>form=_,
          ["table",
           ["tbody",
            ["tr",
             ["td", { border:"none" }, ["label", { 'for':"username" }, "Login Name:"]],
             ["td", ["input", { type:"text", size:"20", id:"username", name:"username",
                                autocapitalize: "off", autocorrect: false }, _=>nuser=_]]],
            ["tr",
             ["td", ["label", {'for':"new_password" }, "Password:"]],
             ["td", ["input", { type:"password", size:"10", id:"new_password", name:"new_password" }, _=>npass=_]]],
            ["tr",
             ["td", ["label", {'for':"new_password2" }, "Verify Password:"]],
             ["td", ["input", { type:"password", size:"10", id:"new_password2", name:"new_password2" }, _=>npass2=_]]]]],
          ["input", { type:"submit", value: "Create New User", id:"ok"     }, _=>ok=_],
          ["input", { type:"button", value: "Cancel",          id:"cancel" }, _=>cancel=_]]]
    ), { title:"Create a new user", className: "create-dialog", cancel: ()=>this.cancel(alert) });
    form.onsubmit = function(ev) {
        doc.clear(error);

        if (!nuser.value)                doc([error, ["li", "Missing a Login Name!"]]);
        if (!npass.value)                doc([error, ["li", "Missing a Password!"]]);
        if (npass.value != npass2.value) doc([error, ["li", "Passwords don't match!"]]);

        if (!error.firstChild) {
            doc([error, ["li", "Creating user. Please wait..."]]);
            ok.disabled = true;
            cancel.disabled = true;
            this.authenticate(nuser.value, npass.value, true, function() {
                doc.clear(error);
                if (this.error)
                    doc([error, ["li", this.error]]);
                else
                    this.cancel(alert);
                ok.disabled = false;
                cancel.disabled = false;
            }.bind(this));
        }
        ev.preventDefault();
    }.bind(this);

    cancel.onclick = function() {
        this.cancel(alert);
        this.state = "idle";
        this.update_div();
        return false;
    }.bind(this);
    gen_return_wrapper(nuser,  function() { npass.focus(); });
    gen_return_wrapper(npass,  function() { npass2.focus(); });
    gen_return_wrapper(npass2, function() { ok.click(); });
}

login.prototype.cancel = function(alert) {
    alert.close();
    this.state = "idle";
    this.update_div();
}

module.exports = new login;
