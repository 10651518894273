// Copyright © 2016 David Caldwell <david@porkrind.org>
var Login = require('./login');
var Debug = require('./debug'), reported_errors=Debug.reported_errors;
var dom = require('./dom'), URL=dom.URL;
var ajax = require('./ajax'), makeurl=ajax.makeurl;
var plat = require('./platform');

function forum_post(category, user, subject, message) {
    window.location = makeurl("forum", "post", "discussion", { category: category, name: subject, body: message });
}

function where() {
    return window.the_game ? "> Game: "+window.the_game.name+"\n"+
                             '> Game #: ['+window.the_game.seed+']('+URL.protocol+'://'+URL.address+makeurl(window.the_game.name, {game: window.the_game.seed})+")\n"
                           : "> URL: "+URL.path+"\n";
}

function note() {
    return ("<!-- ---------------------------------------------------------------------\n"+
            "NOTE: This will be posted publicly in our forum.\n"+
            "- Do _not_ include any private information\n"+
            "- Please be polite\n"+
            "- Check back in the forum later to see if someone has responded to your message\n"+
            "---------------------------------------------------------------------- -->\n");
}

function env_info() {
    return "> Browser: "+navigator.userAgent+"\n"+
           "> Window: "+plat.windowWidth()+"x"+plat.windowHeight()+"\n"+
           "> JS-Version: "+window.js_version+"\n"+
           (!reported_errors || !reported_errors.length ? "" :
            "> Errors: "+reported_errors.join(" ")+"\n");
}

function genbug() {
    forum_post('bug-reports', Login.user, undefined,
               where()+
               env_info()+
               "\n---\n"+
               "#### Please describe the problem in as much detail as possible:\n"+
               note()+
               "\n");
    return false;
}

function genfeature() {
    forum_post('feature-requests', Login.user, undefined,
               where()+
               env_info()+
               "\n---\n"+
               "#### What would you like to see?\n"+
               note()+
               "\n");
    return false;
}

module.exports = {
    genbug: genbug,
    genfeature: genfeature
};
